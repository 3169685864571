import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

const resVehiculeField = `
  id
  reference
  name
  model
  brand
  owner
  keyNumber
  kgCoTwoPerKm
  type
  subType
  additionalSubType
  energy
  numberPlate
  forProduction
  typeName
  energyName
  taxHorsepower
  grayCardXid
  grayCardVersoXid
`;

export const getVehicules = async (ForProduction = true, UserId = 0) => {
	// if (isNil(userId)) return;
	try {
		const {
			data: { GetVehicules }
		} = await apollo.query({
			query: gql`
				query ($UserId: Int, $ForProduction: Boolean) {
					GetVehicules(UserId: $UserId, ForProduction: $ForProduction) {
						${resVehiculeField}
					}
				}
			`,
			variables: {
				ForProduction,
				UserId
			},
			fetchPolicy: 'no-cache'
		});
		return GetVehicules;
	} catch (e) {
		console.log({ e });
	}
};

export const getVehicule = async (vehiculeId) => {
	if (isNil(vehiculeId)) return;
	// if (isNil(vehiculeId)) return;
	try {
		const {
			data: { GetVehicule }
		} = await apollo.query({
			query: gql`
				query ($VehiculeId: ID!) {
					GetVehicule(VehiculeId: $VehiculeId) {
						${resVehiculeField}
					}
				}
			`,
			variables: {
				VehiculeId: parseInt(vehiculeId)
			},
			fetchPolicy: 'no-cache'
		});
		return GetVehicule;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} vehiculeId
 */
export const delVehicule = async (vehiculeId) => {
	if (isNil(vehiculeId)) return;
	try {
		const mutation = gql`
			mutation ($VehiculeId: ID!) {
				DelVehicule(VehiculeId: $VehiculeId)
			}
		`;
		await apollo.mutate({
			mutation,
			variables: {
				VehiculeId: parseInt(vehiculeId)
			}
		});
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} vehiculeId
 */
export const addUpdVehicule = async ({ vehiculeId = 0, payload }) => {
	if (isNil(vehiculeId)) return;
	try {
		const mutation = gql`
			mutation ($VehiculeId: ID!, $UpdatedVehicule: VehiculeInput!) {
				AddUpdVehicule(VehiculeId: $VehiculeId, UpdatedVehicule: $UpdatedVehicule) {
					${resVehiculeField}
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				VehiculeId: parseInt(vehiculeId),
				UpdatedVehicule: payload
			}
		});
		return data.AddUpdVehicule;
	} catch (e) {
		console.error({ e });
	}
};
