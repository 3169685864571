var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Verification code"),
        "ok-title": _vm.FormMSG(2, "Save"),
        "ok-disabled": !_vm.enableSaveButton,
        "ok-variant": "success",
        "cancel-title": _vm.FormMSG(3, "Cancel"),
        "cancel-variant": "primary",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.saveClick, hidden: _vm.emitCloseEvent },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("email-validation", {
        attrs: { "use-alert": "", "email-value": _vm.emailForValidation },
        on: { "verification-email:check": _vm.handleVerificationEmailCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }