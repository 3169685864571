import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

// firstName,
// name,
// email,
// phone,
// iban,
// bic,
// language: parseInt(language, 10),
// socSecNumber,
// civilStatus: parseInt(civilStatus, 10),
// nationalRegistryNumber,
// nationality,
// birthDate,
// companyNumber,
// picture,
// placeOfBirth,
// placeOfBirthZip,
// gender: parseInt(gender, 10),
// driverLicense,
// allergies,
// spouseName,
// spouseFirstName,
// spouseBirthDate,
// spouseProfession,
// spouseHasSalary,
// numberOfDependentChild: parseInt(numberOfDependentChild, 10),
// numberOfDisabledChild: parseInt(numberOfDisabledChild, 10),
// numberOfDependentPerson: parseInt(numberOfDependentPerson, 10),
// numberOfDisabledPerson: parseInt(numberOfDisabledPerson, 10),
// personToContact,
// personToContactPhone,
// disabled,
// color,
// idNumber,
// shoeSize,
// tShirtSize,
// showMyEmailInCrew,
// showMyPhoneInCrew,
// street: address.street,
// number: address.number,
// zip: address.zip,
// city: address.city,
// box: address.box,
// state: address.state,
// country: address.country,
// countryCode: address.countryCode,
// countryKey: parseInt(address.countryKey, 10),
// lat: parseFloat(address.lat),
// lng: parseFloat(address.lng),
// idName,
// taxesPercentage: parseInt(taxesPercentage, 10),
// studyLevel,
// size,
// pantSize,
// jacketSize,
// vehicleName,
// vehicleBrand,
// vehicleModel,
// vehicleNumberPlate,
// vehicleType,
// vehicleSubType,
// vehicleAdditionalSubType,
// vehicleEnergy,
// vehicleKgCoTwo,
// vehicleTaxHorsepower,
// vehicleGrayCardXid,
// vehicleGrayCardVersoXid,
// pictureRib,
// maidenName,
// birthCountry,
// title,
// congesSpectacle,
// bankAccountOwnerName,
// bankDetailXid,
// taxCountry,
// socialSecurityCardXid,
// insuranceCertificateXid,
// socialSecurityCenter,
// socialSecurityCenterAddressId,
// lastMedicalVisit,
// medicalVisitValidity,
// medicalVisitDocumentXid,
// disabilityNotification,
// disabilityType,
// disabilityStatus,
// invalidity,
// rqth,
// invalidityPourcentage: +invalidityPourcentage,
// retired,
// retirementDate,
// trainLoyaltyCard,
// trainDiscountCard,
// flyingLoyaltyCard,
// otherAirlineCard,
// residencePermitNumber,
// residencePermitEndValidity,
// residentPermitXid,
// workCardXid,
// pseudonym,
// professionalAssociation,
// postalAddressID,
// postalAddress,
// driverLicenseNumber,
// session1A,
// session1B,
// session2A,
// session2B,
// session3A,
// session3B,
// session4A,
// session4B,
// session5A,
// session5B,
// session6A,
// session6B,
// session7A,
// session7B,
// session8A,
// session8B

export const updMyProfile = async (profileInput, customFieldValueInputs = []) => {
	const mutation = gql`
		mutation UPD_MY_PROFILE($profileInput: UserInput!, $customFieldValueInputs: [CustomFieldValueInput]) {
			UpdMyProfile(newProfile: $profileInput, CustomFieldValueInput: $customFieldValueInputs)
		}
	`;
	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables: {
			profileInput,
			customFieldValueInputs
		},
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};

export const updatePictureProfile = async (variables, field) => {
	const mutation = gql`
		mutation ($${field}: String) {
			UpdMyProfile(newProfile: { ${field}: $${field} })
		}
	`;

	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};

export const updatePictureVehicule = async (variables) => {
	const mutation = gql`
		mutation ($vehicule: VehiculeInput) {
			UpdMyProfile(newProfile: { vehicule: $vehicule })
		}
	`;

	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};

export const updatePhoneProfile = async (phone) => {
	const mutation = gql`
		mutation ($phone: String!) {
			UpdMyProfile(newProfile: { phone: $phone })
		}
	`;

	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables: {
			phone
		},
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};

export const updateEmailProfile = async (email) => {
	const mutation = gql`
		mutation ($email: String!) {
			UpdMyProfile(newProfile: { email: $email })
		}
	`;

	const {
		data: { UpdMyProfile }
	} = await apollo.mutate({
		mutation,
		variables: {
			email
		},
		fetchPolicy: 'no-cache'
	});

	return UpdMyProfile;
};
