import { isNil } from "@/shared/utils";
import ibanJsonValidatorList from "@/statics/iban-validation-list.json";

/**
 * @param {!String} value
 * @return {String}
 */
const trimAndUpperCase = (value) => {
  if (isNil(value)) return "";
  const trim = value.replace(/\s/g, "");
  return trim.toUpperCase();
};

/**
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be
 * (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 *
 * @param {!String} value
 * @return {Boolean}
 */
export const isValidIBANNumber = (value) => {
  if (isNil(value)) return false;
  const input = trimAndUpperCase(value);

  const countryCode = input.slice(0, 2);
  const countryData = ibanJsonValidatorList.find((item) =>
    item.code === countryCode
  );

  if (isNil(countryData)) return false;

  const expectedLength = countryData.length;
  // const regex = new RegExp(countryData.regex);
  const code = input.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

  return input.length === expectedLength && code;
};

/**
 * @param {!String} value
 * @return {Boolean}
 *
 * BIC is the business identifier code (ISO 9362). This BIC check is not a guarantee for authenticity.
 *
 * BIC pattern: BBBBCCLLbbb (8 or 11 characters long; bbb is optional)
 *
 * BIC definition in detail:
 * - First 4 characters - bank code (only letters)
 * - Next 2 characters - ISO 3166-1 alpha-2 country code (only letters)
 * - Next 2 characters - location code (letters and digits)
 *   a. shall not start with '0' or '1'
 *   b. second character must be a letter ('O' is not allowed) or one of the following digits ('0' for test (therefore not allowed),
 *      '1' for passive participant and '2' for active participant)
 * - Last 3 characters - branch code, optional (shall not start with 'X' except in case of 'XXX' for primary office) (letters and digits)
 */
export const isBic = (value) => {
  if (isNil(value)) return false;
  const input = trimAndUpperCase(value);

  const _v = input.toUpperCase();
  const _r = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/;
  return _r.test(_v);
};
