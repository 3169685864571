var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-change-phone",
        title: _vm.FormMSG(3, "Change phone"),
        "ok-title": _vm.FormMSG(1, "Save"),
        "ok-disabled": _vm.saveDisabled,
        "ok-variant": "success",
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "cancel-variant": "primary",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { ok: _vm.saveClick, hidden: _vm.emitCloseEvent },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.FormMSG(
                5,
                "If you change your phone, the old phone will no longer be valid to send notifications from TheGreenShot"
              )
            ) +
            "\n\t"
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            {
              class: _vm.$screen.width < 567 ? "mb-3" : "",
              attrs: { cols: _vm.$screen.width < 567 ? 12 : 8 },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _vm.$screen.width >= 567
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-input-group-prepend",
                          { staticStyle: { height: "100%" } },
                          [
                            _c(
                              "b-input-group-text",
                              {
                                staticStyle: {
                                  "border-radius": "0.25rem 0 0 0.25rem",
                                },
                              },
                              [
                                _c("Phone", {
                                  attrs: { color: "#4d4e4f", size: 16 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("VuePhoneNumberInput", {
                      attrs: {
                        "error-color": "#EA4E2C",
                        "valid-color": "#00AF75",
                        size: "sm",
                        translations: _vm.optionsTranslation,
                        "default-country-code": "BE",
                      },
                      on: { update: _vm.handleUpdatePhoneNumber },
                      model: {
                        value: _vm.newPhone,
                        callback: function ($$v) {
                          _vm.newPhone = $$v
                        },
                        expression: "newPhone",
                      },
                    }),
                  ],
                  1
                ),
                _vm.$screen.width < 567
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-input-group-prepend",
                          { staticStyle: { height: "100%" } },
                          [
                            _c(
                              "b-input-group-text",
                              {
                                staticStyle: {
                                  "border-radius": "0 0.25rem 0.25rem 0",
                                },
                              },
                              [
                                _c("Phone", {
                                  attrs: { color: "#4d4e4f", size: 16 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "b-col",
            { attrs: { cols: _vm.$screen.width < 567 ? 12 : 4 } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    block: "",
                    disabled: _vm.disableVerifyPhone || _vm.loadingVerifyPhone,
                  },
                  on: { click: _vm.verifyPhone },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.labelPhoneAction) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.stateError.phone.invalid === true
        ? _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(65, "Phone invalid")) + "\n\t"
            ),
          ])
        : _vm._e(),
      _vm.stateError.phone.sameOf === true
        ? _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    66,
                    "The new phone number must be different from your old phone number"
                  )
                ) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm.$screen.width < 567 && _vm.showVerificationPhone
        ? _c("p", [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.FormMSG(58, "Enter SMS verfication code")) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm.showVerificationPhone
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "8" } }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "form-group",
                    attrs: {
                      horizontal: true,
                      id: "fieldset-phone-validation",
                      "aria-labelledby": "aria-phone-validation",
                    },
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _vm.$screen.width >= 567
                        ? _c(
                            "legend",
                            {
                              staticClass:
                                "col-8 bv-no-focus-ring col-form-label",
                              attrs: {
                                tabindex: "-1",
                                id: "aria-phone-validation",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      58,
                                      "Enter phone verfication code"
                                    )
                                  ) +
                                  " :\n\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              state: _vm.stateError.phoneCodeValidation,
                              placeholder: "ex: 1234",
                            },
                            on: { input: _vm.handleInputPhoneCodeValidation },
                            model: {
                              value: _vm.phoneCodeValidation,
                              callback: function ($$v) {
                                _vm.phoneCodeValidation = $$v
                              },
                              expression: "phoneCodeValidation",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm.stateError.phoneCodeValidation !== null &&
              _vm.stateError.phoneCodeValidation === false
                ? _c(
                    "b-col",
                    { staticClass: "pt-2", attrs: { cols: "4" } },
                    [_c("X", { attrs: { color: "#B11548", size: 20 } })],
                    1
                  )
                : _vm._e(),
              _vm.stateError.phoneCodeValidation === true
                ? _c(
                    "b-col",
                    {
                      class: _vm.$screen.width < 471 ? "pt-2" : "pt-2",
                      attrs: { cols: "4" },
                    },
                    [_c("Check", { attrs: { color: "#28a745", size: 20 } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }