<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Verification code')"
		:ok-title="FormMSG(2, 'Save')"
		:ok-disabled="!enableSaveButton"
		ok-variant="success"
		:cancel-title="FormMSG(3, 'Cancel')"
		cancel-variant="primary"
		no-close-on-backdrop
		no-close-on-esc
		@ok="saveClick"
		@hidden="emitCloseEvent"
	>
		<email-validation use-alert :email-value="emailForValidation" @verification-email:check="handleVerificationEmailCheck" />
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { X, Check } from 'lucide-vue';
import EmailValidation from '@/components/Login/EmailValidation';

export default {
	name: 'ValidationCodeModal',

	components: {
		X,
		Check,
		EmailValidation
	},

	mixins: [languageMessages],

	props: {
		emailForValidation: { type: String, default: '' },
		open: { type: Boolean, default: false }
	},

	data() {
		return {
			enableSaveButton: false
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		handleVerificationEmailCheck(payload) {
			this.enableSaveButton = payload.emailVerified;
		},
		saveClick() {
			this.$emit('validation-code-modal:save');
		},
		emitCloseEvent() {
			this.$emit('modal:close');
		}
	}
};
</script>

<style scoped></style>
