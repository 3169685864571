<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		id="modal-change-phone"
		v-model="isOpen"
		:title="FormMSG(3, 'Change phone')"
		:ok-title="FormMSG(1, 'Save')"
		:ok-disabled="saveDisabled"
		ok-variant="success"
		:cancel-title="FormMSG(2, 'Cancel')"
		cancel-variant="primary"
		no-close-on-backdrop
		no-close-on-esc
		@ok="saveClick"
		@hidden="emitCloseEvent"
	>
		<b-alert variant="warning" show>
			{{ FormMSG(5, 'If you change your phone, the old phone will no longer be valid to send notifications from TheGreenShot') }}
		</b-alert>
		<b-row class="mb-3">
			<b-col :cols="$screen.width < 567 ? 12 : 8" :class="$screen.width < 567 ? 'mb-3' : ''">
				<div class="d-flex">
					<div v-if="$screen.width >= 567">
						<b-input-group-prepend style="height: 100%">
							<b-input-group-text style="border-radius: 0.25rem 0 0 0.25rem">
								<Phone color="#4d4e4f" :size="16" />
							</b-input-group-text>
						</b-input-group-prepend>
					</div>
					<div>
						<VuePhoneNumberInput
							v-model="newPhone"
							error-color="#EA4E2C"
							valid-color="#00AF75"
							size="sm"
							:translations="optionsTranslation"
							default-country-code="BE"
							@update="handleUpdatePhoneNumber"
						/>
					</div>
					<div v-if="$screen.width < 567">
						<b-input-group-prepend style="height: 100%">
							<b-input-group-text style="border-radius: 0 0.25rem 0.25rem 0">
								<Phone color="#4d4e4f" :size="16" />
							</b-input-group-text>
						</b-input-group-prepend>
					</div>
				</div>
			</b-col>
			<b-col :cols="$screen.width < 567 ? 12 : 4">
				<b-button variant="primary" block :disabled="disableVerifyPhone || loadingVerifyPhone" @click="verifyPhone">
					<span>
						{{ labelPhoneAction }}
					</span>
				</b-button>
			</b-col>
		</b-row>
		<b-form-invalid-feedback v-if="stateError.phone.invalid === true" force-show>
			{{ FormMSG(65, 'Phone invalid') }}
		</b-form-invalid-feedback>
		<b-form-invalid-feedback v-if="stateError.phone.sameOf === true" force-show>
			{{ FormMSG(66, 'The new phone number must be different from your old phone number') }}
		</b-form-invalid-feedback>
		<p v-if="$screen.width < 567 && showVerificationPhone">
			{{ FormMSG(58, 'Enter SMS verfication code') }}
		</p>
		<b-row v-if="showVerificationPhone">
			<b-col cols="8">
				<fieldset class="form-group" :horizontal="true" id="fieldset-phone-validation" aria-labelledby="aria-phone-validation">
					<div class="form-row">
						<legend v-if="$screen.width >= 567" tabindex="-1" class="col-8 bv-no-focus-ring col-form-label" id="aria-phone-validation">
							{{ FormMSG(58, 'Enter phone verfication code') }} :
						</legend>
						<div class="col">
							<b-form-input
								v-model="phoneCodeValidation"
								:state="stateError.phoneCodeValidation"
								placeholder="ex: 1234"
								@input="handleInputPhoneCodeValidation"
							/>
						</div>
					</div>
				</fieldset>
			</b-col>
			<b-col v-if="stateError.phoneCodeValidation !== null && stateError.phoneCodeValidation === false" cols="4" class="pt-2">
				<X color="#B11548" :size="20" />
			</b-col>
			<b-col v-if="stateError.phoneCodeValidation === true" cols="4" :class="$screen.width < 471 ? 'pt-2' : 'pt-2'">
				<Check color="#28a745" :size="20" />
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { phoneValidationCode, checkLicensePhoneValidationCode } from '@/cruds/registration.crud';
import { X, Check, Phone } from 'lucide-vue';
import _ from 'lodash';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { NANP_COUNTRY_CODES } from '@/shared/constants';

export default {
	name: 'ChangePhoneModal',

	components: {
		X,
		Check,
		Phone,
		VuePhoneNumberInput
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		open: { type: Boolean, default: false },
		profile: { type: Object, default: () => ({}) }
	},

	data() {
		return {
			newPhone: '',
			newPhoneE164: '',
			stateError: {
				phone: {
					global: null,
					sameOf: null,
					invalid: null
				},
				phoneCodeValidation: null
			},
			phoneCodeValidation: '',
			disableVerifyPhone: true,
			codeVerified: false,
			loadingVerifyPhone: false,
			dataToCheck: {
				phone: '',
				phoneCodeValidation: 0,
				licenseId: ''
			},
			isNANPCountry: false
		};
	},

	computed: {
		labelPhoneAction() {
			if (this.isNANPCountry) {
				return this.FormMSG(159, 'Validated');
			}
			if (this.dataToCheck.phoneCodeValidation !== 0 && !this.loadingVerifyPhone) {
				return this.FormMSG(48, 'Resend code');
			}
			if (!this.loadingVerifyPhone && this.dataToCheck.phoneCodeValidation === 0) {
				return this.FormMSG(49, 'Verify phone');
			}
			if (this.loadingVerifyPhone) {
				return this.FormMSG(50, 'Check phone');
			}
		},
		showVerificationPhone() {
			if (this.dataToCheck.phoneCodeValidation !== 0) {
				return true;
			}
			return false;
		},
		optionsTranslation() {
			return {
				countrySelectorLabel: this.FormMSG(54, 'Country code'),
				countrySelectorError: this.FormMSG(55, 'Choose a country'),
				phoneNumberLabel: this.FormMSG(56, 'Phone number'),
				example: 'Ex : '
			};
		},
		saveDisabled() {
			if (!this.isNANPCountry) {
				return !(this.newPhoneE164.length !== 0 && this.dataToCheck.phone === this.newPhoneE164 && this.codeVerified);
			} else {
				return !(this.newPhoneE164.length !== 0 && this.stateError.phoneCodeValidation === true);
			}
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}
			return false;
		}
	},

	methods: {
		async handleInputPhoneCodeValidation() {
			let verif = await this.verifyMatchingCode();
			if (!verif) {
				this.stateError.phoneCodeValidation = false;
				this.codeVerified = false;
			} else {
				this.stateError.phoneCodeValidation = true;
				this.codeVerified = true;
			}
		},
		async verifyPhone() {
			if (!this.isNANPCountry) {
				this.loadingVerifyPhone = true;
				const phone = this.newPhoneE164;
				const result = await phoneValidationCode({
					userName: this.profile.name,
					userFirstName: this.profile.firstName,
					email: this.profile.email,
					phone: this.newPhoneE164
				});
				this.dataToCheck.licenseId = result.id;
				this.dataToCheck.phoneCodeValidation = 1;
				// this.dataToCheck.phoneCodeValidation = '1234'
				this.dataToCheck.phone = phone;

				this.phoneCodeValidation = '';
				this.stateError.phoneCodeValidation = null;
				this.loadingVerifyPhone = false;
			} else {
				this.stateError.phoneCodeValidation = true;
			}
		},
		async verifyMatchingCode() {
			if (this.dataToCheck.licenseId) {
				try {
					const isValidFromServer = await checkLicensePhoneValidationCode(this.dataToCheck.licenseId, this.phoneCodeValidation);
					return isValidFromServer.CheckLicensePhoneValidationCode;
				} catch (error) {
					return false;
				}
			}
		},
		handleUpdatePhoneNumber(payload) {
			if (payload.e164) {
				this.newPhoneE164 = payload.e164;
			} else {
				this.newPhoneE164 = '';
			}
			if (_.has(payload, 'isValid')) {
				if (payload.isValid) {
					if (this.profile.phone !== this.newPhoneE164) {
						this.disableVerifyPhone = false;
						this.stateError.phone.invalid = null;
						this.stateError.phone.sameOf = null;
					} else {
						this.disableVerifyPhone = true;
						this.stateError.phone.invalid = null;
						this.stateError.phone.sameOf = true;
					}
				} else {
					this.disableVerifyPhone = true;
					this.stateError.phone.invalid = true;
					this.stateError.phone.sameOf = null;
				}
			}
			if (NANP_COUNTRY_CODES.includes(payload.countryCode)) {
				this.isNANPCountry = true;
			}
		},
		initData() {
			this.newPhone = '';
			this.newPhoneE164 = '';
			this.stateError = {
				phone: {
					global: null,
					sameOf: null,
					invalid: null
				},
				phoneCodeValidation: null
			};
			this.phoneCodeValidation = '';
			this.disableVerifyPhone = true;
			this.loadingVerifyPhone = false;
			this.dataToCheck = {
				phone: '',
				phoneCodeValidation: 0
			};
		},
		saveClick() {
			this.$emit('change-phone-modal:save', {
				newPhoneE164: this.newPhoneE164
			});
			this.initData();
		},
		handleInputEmailCodeValidation(value) {
			if (parseInt(this.dataToCheck.emailCodeValidation) === parseInt(value)) {
				this.stateError.emailCodeValidation = true;
			} else {
				this.stateError.emailCodeValidation = false;
			}
		},
		emitCloseEvent() {
			this.initData();
			this.$emit('modal:close');
		}
	}
};
</script>

<style scoped></style>
